import React from 'react'

import { Typography } from '@mui/material'
import { Layout, Section } from 'components'

const H2 = ({ children }) => (
  <Typography variant="h2" sx={{ mb: 1, mt: 4 }}>
    {children}
  </Typography>
)

const PrivacyPolicyPage = () => (
  <Layout title="Privacy Policy">
    <Section my={[8, 10]}>
      <Typography
        variant="h1"
        sx={{
          mb: {
            xs: 2,
            md: 4,
          },
        }}
      >
        Privacy policy
      </Typography>

      <Typography variant="body1" paragraph>
        This Privacy Policy describes how this website, owned and operated by
        Appeal B.V. (collectively, “Appeal”, “we”, or “our”), collects and uses
        data from website visitors and individuals reaching out to us via email
        or using the provided contact form.
      </Typography>
      <H2>What we do</H2>
      <ul>
        <li>
          <Typography variant="body1">
            When browsing our website, we process the website visitor’s IP,
            based on the ground of legitimate interest, in order to be able to
            serve our website.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            In addition, we collect the information that website visitors
            voluntarily submit via email or our contact form, based on consent.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Submitted personal data is securely stored on our servers for one
            year.{' '}
          </Typography>
        </li>
      </ul>

      <H2>What we don’t do</H2>
      <ul>
        <li>
          <Typography variant="body1">
            We do not track website visitors in any way.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            We do not store information about website visitors.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            We do not subject individuals to automated decision making.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            We do not process personal data outside of the EU in any way.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">We do not use cookies. </Typography>
        </li>
      </ul>
      <H2>Right to access</H2>
      <Typography variant="body1" paragraph>
        Individuals have the right to access, correct and delete their personal
        data at any time. Please send us an email if you wish to do so.
      </Typography>

      <H2> Contacting us</H2>

      <Typography variant="body1" paragraph>
        If you have any questions about this privacy policy, please contact us
        at info@appeal.services
      </Typography>
      <Typography variant="body1" paragraph>
        Appeal B.V. is located at: <br />
        Torenallee 429 <br />
        5617 BS Eindhoven <br />
        CoC 76060667 <br />
      </Typography>

      <Typography variant="body2" paragraph>
        Last updated on February 23, 2022.
      </Typography>
    </Section>
  </Layout>
)

export default PrivacyPolicyPage
